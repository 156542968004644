import axios, { Axios } from 'axios'
import { stringify } from 'qs'
import type {
  AxiosRequestConfig,
  Method,
  AxiosResponse,
  AxiosError,
} from 'axios'
import lodash from 'lodash'
import { EBIKE_URL } from './api.url'

class GlobalAxios extends Axios {
  protected RequestConfig: AxiosRequestConfig

  protected alertMsg: string

  protected toastFlag: boolean

  protected networkFlag = true

  protected errorFlag = true

  constructor(props: {
    axiosOptions: AxiosRequestConfig
    alertMsg?: string
    toastFlag?: boolean
  }) {
    const { axiosOptions, alertMsg = '', toastFlag = true } = props
    super(axiosOptions)
    this.RequestConfig = this.config(axiosOptions)
    this.alertMsg = alertMsg
    this.toastFlag = toastFlag
  }

  protected config(obj: AxiosRequestConfig) {
    const headers = obj.headers || {}
    const data: AxiosRequestConfig = {
      headers: {
        'content-type': 'application/json',
        ...headers,
      },
      baseURL: EBIKE_URL,
      timeout: 8000,
      paramsSerializer: (params) => {
        return stringify(params)
      },
      ...obj,
    }
    return data
  }

  protected axiosError(error: any) {
    const errorData = lodash.get(error, 'response.data.error', '')
    if (__DEV__) {
      console.log('axiosError: ', errorData)
    }
    let newError: Partial<API.ErrorData> = {}
    if (errorData) {
      newError = {
        ...errorData,
        message: errorData?.detail || '',
        stack: errorData?.status,
        name: errorData?.id || '',
      }
    } else {
      newError = {
        message: error.message,
        stack: error.stack,
        name: error.name,
      }
    }
    return newError
  }

  protected async http<P, R>(
    config: AxiosRequestConfig,
  ): Promise<API.RequestAPI<P, AxiosError>> {
    const res: AxiosResponse<API.RequestAPI<P, AxiosError>, R> = await axios(
      config,
    )
    if (__DEV__) {
      console.log(
        `${config.method}---${this.alertMsg} Api---Return the result:`,
        res,
      )
    }
    if (res.data.status) {
      return { status: true, payload: res.data.payload }
    }
    return Promise.reject(this.axiosError(res.data.error))
  }

  async fetch<P = any, R = any>(
    method?: Method,
    data?: R,
  ): Promise<API.RequestAPI<P, AxiosError>>
  async fetch<P, R>(
    method: Method = 'GET',
    data?: R,
  ): Promise<API.RequestAPI<P, AxiosError>> {
    return this.mergeConfig<P, R>(method, data)
  }

  protected async mergeConfig<P, R>(
    method: Method,
    data?: R,
  ): Promise<API.RequestAPI<P, AxiosError>> {
    const config = GlobalAxios.HeaderConfig({
      ...this.RequestConfig,
      method,
      ...(() => {
        if (method.toLocaleLowerCase() === 'get') {
          return { params: data }
        }
        return { data }
      })(),
    })
    if (__DEV__) {
      console.log(
        `${method}---${this.alertMsg} Api---Request configuration item`,
        config,
      )
    }
    return this.http<P, R>(config)
  }

  // 添加新的 header，之后需要token，可以完善
  static HeaderConfig(req: AxiosRequestConfig) {
    return req
  }

  static async externalFetch(Url: string, init?: RequestInit | undefined) {
    try {
      const req = await fetch(Url, init)
      if (req.ok) {
        return req.json()
      }
    } catch (error) {
      return false
    }
  }
}

axios.interceptors.response.use(
  (config) => {
    const newConfig = config
    if (config.status === 200) {
      newConfig.data = {
        payload: config.data,
        status: true,
      }
    }
    return newConfig
  },
  (error) => {
    return { data: { status: false, error } }
  },
)

export { GlobalAxios }
