import React, { useCallback, useState } from 'react'
import { InfiniteScroll, List, PullToRefresh } from 'antd-mobile'
import { GlobalAxios } from '../../api/http'
import { PullStatus } from 'antd-mobile/es/components/pull-to-refresh'
import './index.css'
import { useLocation } from 'react-router-dom'

interface NewsItem {
  id: string
  title: string
  description: string
  link: string
}

interface Pagination {
  total: number
  page: number
}

interface ApiResponse {
  list: NewsItem[]
  pagination: Pagination
}

const statusRecord: Record<PullStatus, string> = {
  pulling: '用力拉',
  canRelease: '松开吧',
  refreshing: '玩命加载中...',
  complete: '好啦',
}

const NewsListScreen: React.FC = () => {
  const location = useLocation()
  const [data, setData] = useState<NewsItem[]>([])
  const [page, setPage] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)

  const loadData = async (page: number) => {
    const response = await fetchData(page + 1)
    if (response.pagination.page === 1) {
      setData(response.list)
    } else {
      setData((prevData) => [...prevData, ...response.list])
    }
    setPage(response.pagination.page)
    setHasMore(data.length < response.pagination.total)
  }

  const fetchData = async (page: number): Promise<ApiResponse> => {
    const queryParams = new URLSearchParams(location.search)
    const hl = queryParams.get('hl') || 'en'
    const res = await GlobalAxios.externalFetch(
      `https://biceek.news/api/v1/headlines?hl=${hl}&cat=ebike&page=${page}`,
    )
    if (res) {
      return res
    }
    return {
      list: [],
      pagination: {
        total: 0,
        page: 0,
      },
    }
  }

  const openNewsUrl = useCallback(
    (url: string) => () => {
      window.open(url, '_blank')?.focus()
    },
    [],
  )

  return (
    <PullToRefresh
      onRefresh={async () => {
        await loadData(0)
      }}
      renderText={(status) => {
        return <div>{statusRecord[status]}</div>
      }}
    >
      <List className="news-list">
        {data.map((item) => (
          <List.Item
            key={item.id}
            prefix={
              <img
                src={`https://ewr1.vultrobjects.com/0xbceee/${item.id}.374x214.png`}
                alt={item.title}
                className="news-image"
              />
            }
            onClick={openNewsUrl(item.link)}
          >
            <div className="news-title">{item.title}</div>
            <div className="news-description">{item.description}</div>
          </List.Item>
        ))}
        <InfiniteScroll
          loadMore={() => {
            return loadData(page)
          }}
          hasMore={hasMore}
        />
      </List>
    </PullToRefresh>
  )
}

export default NewsListScreen
