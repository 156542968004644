// export const EBIKE_URL = 'https://ebike-staging.kittens.cloud' //stage env
// export const EBIKE_URL = 'https://api.biceek.com' // release env
const env = process.env.REACT_APP_ENV
export const EBIKE_URL =
  env === 'stage'
    ? 'https://ebike-staging.kittens.cloud'
    : 'https://api.biceek.com'

export const ExternalApiUrl = {
  externalReportById: 'api/v1/external/theftreports/{{id}}',
  externalDeviceByReportId: 'api/v1/external/theftreports/{{id}}/device',
}

export function getLogo(id: string) {
  return EBIKE_URL + `/api/v1/external/theftreports/${id}/device/logo`
}
