import 'mapbox-gl/dist/mapbox-gl.css'
import { useRef, useState } from 'react'
import mapboxgl, { LngLat } from 'mapbox-gl'
import { useDidMount, useDidUpdate } from 'rooks'
import Marker from '../mapbox/marker'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import '../mapbox/index.css'
import { MapContext } from '../mapbox/context'
import ebikeDevice from '../ebikeDevice.svg'
import { useSetState } from 'ahooks'

const defaultCenter: [number, number] = [0, 0]
const defaultZoom = 17
const defaultStyle = 'mapbox://styles/mapbox/streets-v9'
const showZoom = true
const showFullscreen = false

const CircleRadiusScreen = () => {
  const [initMap, setInitMap] = useState<mapboxgl.Map | undefined>()
  const elRef = useRef<HTMLDivElement>()
  const [ready, setReady] = useState(false)
  const [state, setState] = useSetState<{
    contentInset: [number, number]
    visible: boolean
  }>({
    contentInset: [0, 0],
    visible: false,
  })
  const [lngLat, setLngLat] = useSetState({
    lat: 0,
    lng: 0,
  })

  useDidMount(() => {
    const ops: mapboxgl.MapboxOptions = {
      style: defaultStyle,
      accessToken:
        'pk.eyJ1IjoiYm9iY2F0bWluZXIiLCJhIjoiY2t1a3h2Y3dmMjFldDJ3dDltcTR3a2R1bCJ9.Im7PoMZ3X1UPENE71SJTfQ',
      zoom: defaultZoom,
      minZoom: 0,
      maxZoom: 20,
      center: LngLat.convert(defaultCenter),
      container: elRef.current!,
    }
    if (!initMap) {
      const map = new mapboxgl.Map(ops)
      setInitMap(map)
    }
    const CircleRadius = sessionStorage.getItem('CircleRadius')
    if (CircleRadius) {
      const CircleRadiusParse = JSON.parse(CircleRadius)
      setState({
        contentInset: CircleRadiusParse.contentInset,
        visible: true,
      })
    }
  })

  useDidUpdate(() => {
    if (initMap) {
      initMap.on('load', (evt: React.SyntheticEvent<any>) => {
        if (showFullscreen) {
          initMap.addControl(
            new mapboxgl.FullscreenControl({
              container: document.querySelector('body'),
            }),
          )
        }
        if (showZoom) {
          initMap.addControl(
            new mapboxgl.NavigationControl({ showCompass: false }),
            'bottom-right',
          )
        }
        setReady(true)
      })
    }
  }, [initMap])

  const setContainer = (el: HTMLDivElement | null) => {
    if (el) elRef.current = el
  }

  useDidUpdate(() => {
    if (initMap && state.contentInset) {
      initMap.flyTo({
        center: state.contentInset,
        zoom: initMap.getZoom(),
        bearing: 0,
        speed: 8,
        curve: 1,
        easing(t) {
          return t
        },
      })
      setLngLat({
        lat: state.contentInset[1],
        lng: state.contentInset[0],
      })
    }
  }, [initMap, state.contentInset])

  return (
    <MapContext.Provider value={initMap}>
      <div
        ref={setContainer}
        style={{
          width: window.innerWidth,
          height: window.innerHeight,
        }}
      >
        {ready ? (
          <div>
            <div
              style={{
                color: 'red',
                width: '85%',
                position: 'absolute',
                top: 5,
                margin: 0,
                borderRadius: 10,
                height: 60,
                left: 30,
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 22,
                zIndex: 1,
              }}
            >
              Move the pin to set as the center of the circle parameter.
            </div>
            <Marker
              center={state.contentInset}
              draggable={true}
              onDragEnd={(lngLat: LngLat) => {
                setLngLat(lngLat)
                const newWindow = window as any
                if (newWindow?.ReactNativeWebView) {
                  newWindow.ReactNativeWebView.postMessage(
                    JSON.stringify(lngLat),
                  )
                }
              }}
            >
              <img src={ebikeDevice} alt="device" />
            </Marker>
            <div
              style={{
                background: 'rgba(0, 0, 0, 0.5)',
                color: '#fff',
                width: '100%',
                position: 'absolute',
                bottom: '0',
                margin: 0,
                borderRadius: 10,
                height: 60,
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {lngLat.lat},{lngLat.lng}
            </div>
          </div>
        ) : null}
      </div>
    </MapContext.Provider>
  )
}

export default CircleRadiusScreen
