import { useRef } from 'react'
import { BaseBoxMapBox } from '../../../mapbox/base.map'
import { useMount, useSetState } from 'ahooks'

const TrackPlayBackScreen = () => {
  const mapboxRef = useRef<mapboxgl.Map>()
  const [state, setState] = useSetState({
    contentInset: [0, 0],
    coordinates: [],
    visible: false,
  })

  useMount(() => {
    const Geofencing = sessionStorage.getItem('Geofencing')
    if (Geofencing) {
      const GeofencingParse = JSON.parse(Geofencing)
      setState({
        contentInset: GeofencingParse.contentInset,
        coordinates: GeofencingParse.coordinates,
        visible: true,
      })
    }
  })

  return (
    <BaseBoxMapBox
      visible={state.visible}
      onMapboxRef={(e) => (mapboxRef.current = e)}
      mapBoxConfig={{
        center: state.contentInset as any,
        zoom: 16,
      }}
      showGeolocate={false}
      containerStyle={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
      isTrackPlayBack={true}
      coordinates={state.coordinates}
    ></BaseBoxMapBox>
  )
}

export default TrackPlayBackScreen
