import React, { createContext } from 'react'
import type { Context } from 'react'
import MapboxGl from 'mapbox-gl'

export const MapContext = createContext(undefined) as Context<
  MapboxGl.Map | undefined
>

export function withMap<T>(
  Component: React.FunctionComponent<any>,
): React.FunctionComponent<T> {
  return function MappedComponent<T>(props: T) {
    return (
      <MapContext.Consumer>
        {(map) => <Component map={map} {...props} />}
      </MapContext.Consumer>
    )
  }
}
