import { useParams } from 'react-router-dom'
import { TheftReport } from '../mapbox/theftReport.map'

const TheftReportScreen = () => {
  const { id } = useParams<{ id: string }>()
  if (!id) return null
  return (
    <TheftReport
      reportId={id}
      showGeolocate={false}
      containerStyle={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    ></TheftReport>
  )
}

export default TheftReportScreen
