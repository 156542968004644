import CircleFence from '../../../mapbox/circle'
import { useMount, useSetState } from 'ahooks'
import * as turf from '@turf/turf'
import lodash from 'lodash'

const CircleScreen = () => {
  const [state, setState] = useSetState({
    contentInset: [0, 0],
    polygon: [],
    visible: false,
  })

  useMount(() => {
    const Geofencing = sessionStorage.getItem('Geofencing')
    if (Geofencing) {
      const GeofencingParse = JSON.parse(Geofencing)
      setState({
        contentInset: GeofencingParse.contentInset,
        polygon:
          GeofencingParse.polygonType === 'circle'
            ? (createCircularFences(GeofencingParse.polygon) as [])
            : [],
        visible: true,
      })
    }
  })

  const updateFenceCenters = (fences: any[]) => {
    return fences.map((fence: any) => {
      const vertices = fence.data.geometry.coordinates[0]
      const center = turf.center(turf.points(vertices))
      return {
        id: fence.id,
        radius: fence.radius,
        lng: center.geometry.coordinates[0],
        lat: center.geometry.coordinates[1],
      }
    })
  }

  const createCircularFences = (fences: any[]) => {
    if (fences.length) {
      if (lodash.has(fences[0], 'data')) {
        return fences
      } else {
        return fences.map((item) => {
          const center = [item.lng, item.lat]
          const circle = turf.circle(center, item.radius, {
            steps: 64,
            units: 'meters',
          })
          const newFence = { id: item.id, data: circle, radius: item.radius }
          return newFence
        })
      }
    } else {
      return []
    }
  }

  return (
    <CircleFence
      isShow={true}
      featureCollection={state.polygon}
      onSetData={(data: any[]) => {
        if (data) {
          const drawData = {
            polygon: updateFenceCenters(data),
            type: 'circle',
          }
          const newWindow = window as any
          if (newWindow?.ReactNativeWebView) {
            newWindow.ReactNativeWebView.postMessage(JSON.stringify(drawData))
          }
        }
      }}
    />
  )
}

export default CircleScreen
