import 'mapbox-gl/dist/mapbox-gl.css'
import { memo, ReactNode, useRef, useState } from 'react'
import mapboxgl, { LngLat } from 'mapbox-gl'
import { useTranslation } from 'react-i18next'
import { useDidMount, useDidUpdate, useWillUnmount } from 'rooks'
import lodash from 'lodash'
import Marker from '../mapbox/marker'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import './index.css'
import { MapContext } from './context'
import {
  onGetTheftReportById,
  onGetDeviceByTheftReportId,
} from '../service/report'
import ebikeDevice from '../ebikeDevice.svg'
import { getLogo } from '../api/api.url'

interface IProps {
  containerStyle?: React.CSSProperties
  children?: ReactNode
  map?: mapboxgl.Map
  showZoom?: boolean
  showGeolocate?: boolean
  showFullscreen?: boolean
  showGeolocateTrigger?: boolean
  reportId: string
}
const defaultCenter: [number, number] = [0, 0]
const defaultZoom = 15
const defaultStyle = 'mapbox://styles/mapbox/streets-v9'
const TheftReport = memo((props: IProps) => {
  const {
    showZoom = false,
    showGeolocate = true,
    showFullscreen = true,
    showGeolocateTrigger = false,
    reportId = '',
  } = props
  const elRef = useRef<HTMLDivElement>()
  const [ready, setReady] = useState(false)
  const [initMap, setInitMap] = useState<mapboxgl.Map | undefined>(props.map)
  const GeolocateControlRef = useRef<mapboxgl.GeolocateControl>()
  const [theftReport, setTheftReport] = useState<any>({ properties: [] })
  const [device, setDevice] = useState<any>({ name: '' })
  const [center, setCenter] = useState<[number, number]>([0, 0])
  const { t } = useTranslation()

  useDidMount(() => {
    const ops: mapboxgl.MapboxOptions = {
      container: elRef.current!,
      style: defaultStyle,
      accessToken:
        'pk.eyJ1IjoiYm9iY2F0bWluZXIiLCJhIjoiY2t1a3h2Y3dmMjFldDJ3dDltcTR3a2R1bCJ9.Im7PoMZ3X1UPENE71SJTfQ',
      zoom: defaultZoom,
      minZoom: 0,
      maxZoom: 20,
      center: LngLat.convert(defaultCenter),
    }

    if (!initMap) {
      const map = new mapboxgl.Map(ops)
      if (showGeolocate) {
        const GeolocateControl = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          fitBoundsOptions: {
            zoom: map.getZoom(),
          },
          trackUserLocation: true,
          showUserHeading: true,
        })
        GeolocateControlRef.current = GeolocateControl
        map.addControl(GeolocateControl)
      }
      setInitMap(map)
    }

    if (reportId) {
      onGetTheftReportById(reportId).then((res: any) => {
        setTheftReport(res.payload)
      })
      onGetDeviceByTheftReportId(reportId).then((res: any) => {
        const lat = lodash.get(res, 'payload.signals.Location.lat', 0)
        const lng = lodash.get(res, 'payload.signals.Location.lng', 0)
        setDevice(res.payload)
        setCenter([lng, lat])
      })
    }
  })

  useDidUpdate(() => {
    if (initMap) {
      initMap.on('load', (evt: React.SyntheticEvent<any>) => {
        if (showFullscreen) {
          initMap.addControl(
            new mapboxgl.FullscreenControl({
              container: document.querySelector('body'),
            }),
          )
        }
        if (showZoom) {
          initMap.addControl(
            new mapboxgl.NavigationControl({ showCompass: false }),
            'bottom-right',
          )
        }
        if (showGeolocateTrigger && GeolocateControlRef.current) {
          GeolocateControlRef.current.trigger()
        }
        setReady(true)
      })
    }
  }, [initMap, showGeolocateTrigger])

  useDidUpdate(() => {
    if (initMap && center) {
      initMap.flyTo({
        center: center,
        zoom: initMap.getZoom(),
        bearing: 0,
        speed: 0.8,
        curve: 1,
        easing(t) {
          return t
        },
      })
    }
  }, [initMap, center])

  useWillUnmount(() => {
    if (initMap) {
      initMap.remove()
      setReady(false)
    }
  })

  const setContainer = (el: HTMLDivElement | null) => {
    if (el) elRef.current = el
  }
  return (
    <MapContext.Provider value={initMap}>
      <div ref={setContainer} style={{ ...props.containerStyle }}>
        {ready ? (
          <div>
            <Marker center={center}>
              <img src={ebikeDevice} alt="device" />
            </Marker>
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                height: '200px',
                backgroundColor: 'white',
                width: '100%',
                zIndex: 999,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '5px',
                  marginBottom: '5px',
                }}
              >
                {t('theft_report.report_device', {
                  deviceName: device.name,
                })}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '5px',
                  marginBottom: '5px',
                }}
              >
                <img
                  src={getLogo(reportId)}
                  width={'60px'}
                  height={'60px'}
                  style={{ borderRadius: 5 }}
                />
              </div>
              {theftReport.properties.map((item: any) => {
                return (
                  <div
                    style={{
                      marginTop: '5px',
                      marginBottom: '5px',
                      marginLeft: '20px',
                      marginRight: '20px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div> {t('theft_report.title')}</div>
                      <div>{item.key}</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div> {t('theft_report.content')}</div>
                      <div>{item.value}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}
      </div>
    </MapContext.Provider>
  )
})

export { TheftReport }
