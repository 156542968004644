import { useMount, useSetState, useUpdateEffect } from 'ahooks'
import { Outlet, useNavigate } from 'react-router-dom'
import lodash from 'lodash'
import { BaseBoxMapBox } from '../../mapbox/base.map'
import { Fragment, useRef, useState } from 'react'
import Marker from '../../mapbox/marker'
import ebikeDevice from '../../ebikeDevice.svg'
import { Button, Space } from 'antd-mobile'
import DrawScreen from './children/DrawScreen'
import CircleScreen from './children/CircleScreen'

const GeofencingScreen = () => {
  const navigate = useNavigate()
  const moveZoomRef = useRef()
  const [mapbox, setMapbox] = useState<mapboxgl.Map>()
  const [state, setState] = useSetState({
    type: '',
    screenStatus: '0', // 0 = null, 1 = trackPlayBack || CircleRadiusScreen, 2 = oldApp, 3 = circle || draw
    contentInset: [0, 0],
    visible: false,
    screenText: '',
    buttonVisible: false,
    currentZoom: 0,
  })

  useMount(() => {
    // setState({
    //   screenStatus: '3',
    //   screenText: 'circle',
    //   visible: true,
    //   contentInset: [113.26446069297566, 23.108457059951817],
    //   currentZoom: 17
    // })
    // // 获取当前定位
    // if (navigator.geolocation) {
    //   navigator.geolocation.watchPosition(
    //     function (position) {
    //       const userLocation = [
    //         position.coords.longitude,
    //         position.coords.latitude,
    //       ]
    //       console.log('userLocation', userLocation)
    //     },
    //     function (error) {
    //       console.log(error)
    //     },
    //     {
    //       enableHighAccuracy: true,
    //     },
    //   )
    // } else {
    //   console.log('Geolocation is not supported by this browser.')
    // }

    //ios can work
    window.addEventListener('message', handleMessage)
    //android can work
    document.addEventListener('message', handleMessage)
  })

  useUpdateEffect(() => {
    if (mapbox) mapbox.on('zoomend', handleZoomend)
    return () => {
      if (mapbox) mapbox.off('zoomend', handleZoomend)
    }
  }, [mapbox])

  const handleMessage = (event: any) => {
    if (typeof event.data === 'string') {
      if (event.data.includes('polygon')) {
        try {
          const data = JSON.parse(event.data || {})
          sessionStorage.setItem('Geofencing', event.data)
          if (lodash.get(data, 'isTrackPlayBack')) {
            setState({ screenStatus: '1' })
            navigate('trackPlayBack')
          } else {
            moveZoomRef.current = lodash.get(data, 'currentZoom', 18)
            switch (lodash.get(data, 'polygonType')) {
              case 'circle':
                setState({
                  screenStatus: '3',
                  contentInset: data.contentInset,
                  screenText: 'circle',
                  visible: true,
                  currentZoom: lodash.get(data, 'currentZoom', 18),
                })
                break
              case 'draw':
                setState({
                  screenStatus: '3',
                  contentInset: data.contentInset,
                  screenText: 'draw',
                  visible: true,
                  currentZoom: lodash.get(data, 'currentZoom', 18),
                })
                break
              default:
                // 向下兼容，如 APP 还未更新代码则走这里，以后可注销此段代码
                setState({ screenStatus: '2' })
                navigate('oldApp', { state: data })
            }
          }
        } catch (error) {
          setState({ screenStatus: '0' })
          console.error('Error parsing event data:', error)
        }
      } else if (event.data.includes('circleRadius')) {
        try {
          sessionStorage.setItem('CircleRadius', event.data)
          setState({ screenStatus: '1' })
          navigate('CircleRadiusScreen')
        } catch (error) {
          setState({ screenStatus: '0' })
          console.error('Error parsing event data:', error)
        }
      }
    }
  }

  const handleZoomend = (zoomendEvent: any) => {
    const getZoom = zoomendEvent.target.getZoom()
    if (getZoom !== moveZoomRef.current) {
      moveZoomRef.current = getZoom
      const newWindow = window as any
      if (newWindow?.ReactNativeWebView) {
        newWindow.ReactNativeWebView.postMessage(JSON.stringify({ getZoom }))
      }
    }
  }

  const handleDragend = (event: any) => {
    const lngLat = event.target.getLngLat().toArray()
    setState({ contentInset: lngLat })
    setTimeout(() => {
      const newWindow = window as any
      if (newWindow?.ReactNativeWebView) {
        newWindow.ReactNativeWebView.postMessage(JSON.stringify({ lngLat }))
      }
    }, 1000)
  }

  if (state.screenStatus === '1') return <Outlet />
  if (state.screenStatus === '2') return <Outlet />
  if (state.screenStatus === '3') {
    return (
      <BaseBoxMapBox
        visible={state.visible}
        onMapboxRef={setMapbox}
        mapBoxConfig={{
          center: state.contentInset as any,
          zoom: state.currentZoom,
        }}
        showGeolocate={false}
        containerStyle={{
          width: window.innerWidth,
          height: window.innerHeight,
        }}
        isTrackPlayBack={false} // 移除
        coordinates={[]} // 移除
      >
        <Fragment>
          <div
            style={{
              background: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              width: '100%',
              position: 'absolute',
              bottom: '0',
              margin: 0,
              height: 50,
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <p style={{ padding: 0, margin: 0}}>
              {state.contentInset[0]}, {state.contentInset[1]}
            </p>
            <h6 style={{ padding: 0, margin: 0}}>
              Move the pin to set as the center of the circle parameter
            </h6>
          </div>
          <Marker
            center={state.contentInset}
            options={{ draggable: true }}
            onRef={(event) => {
              event.on('dragend', handleDragend)
            }}
          >
            <img src={ebikeDevice} alt="device" />
          </Marker>
          {/* 使用 Outlet 在 web、PC、RN iOS都使用良好，但在 RN Android 的 webView 中有 bug，排查后可能是 webView 插件的问题，先使用组件进行当前页面切换以解决解决bug*/}
          {state.screenText === 'draw' && <DrawScreen />}
          {state.screenText === 'circle' && <CircleScreen />}
          <div
            style={{
              position: 'absolute',
              zIndex: 9999999,
              padding: 10,
              left: 0,
              top: 0,
            }}
          >
            <Space direction="vertical">
              <Button
                disabled={state.buttonVisible}
                style={{ width: 110, height: 40 }}
                onClick={() => {
                  if (state.screenText === 'draw') {
                    setState({ screenText: 'circle', buttonVisible: true })
                  } else {
                    setState({ screenText: 'draw', buttonVisible: true })
                  }
                  setTimeout(() => {
                    setState({ buttonVisible: false })
                  }, 300)
                }}
                size="small"
              >
                &lt;-
                {state.screenText === 'draw' ? ' Circle' : ' Polygon'}
              </Button>
            </Space>
          </div>
        </Fragment>
      </BaseBoxMapBox>
    )
  }

  return null
}

export default GeofencingScreen
