import { BaseBoxMap } from './mapbox/base.map'
import { TheftReport } from './mapbox/theftReport.map'
import Marker from './mapbox/marker'
import MapboxDrawComponent from './mapbox/draw'
import { useDidMount } from 'rooks'
import { useRef, useState } from 'react'
import ebikeDevice from './ebikeDevice.svg'
import { useLocation } from 'react-router-dom'

function GetQueryValue(queryName: string) {
  var query = decodeURI(window.location.search.substring(1))
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == queryName) {
      return pair[1]
    }
  }
  return null
}

function App() {
  const location = useLocation()
  const mapboxRef = useRef<mapboxgl.Map>()
  const [contentInset, setContentInset] = useState<number[]>([])
  const [polygon, setPolygon] = useState([])
  const [isTrackPlayBack, setTrackPlayBack] = useState(false)
  const [coordinates, setCoordinates] = useState([])
  const [id, setId] = useState('')

  useDidMount(() => {
    const id = GetQueryValue('id')
    if (id) {
      setId(id)
    }
    setPolygon(
      location.state.polygon.map((item: any) => {
        return {
          geometry: {
            coordinates: [
              item.map((coordinate: any) => {
                return [coordinate.lat, coordinate.lng]
              }),
            ],
            type: 'Polygon',
          },
          properties: {},
          type: 'Feature',
        }
      }),
    )
    setContentInset(location.state.contentInset)
    location.state.coordinates && setCoordinates(location.state.coordinates)
    setTrackPlayBack(location.state.isTrackPlayBack)
  })

  return (
    <div className="App">
      {!id && contentInset.length ? (
        <BaseBoxMap
          onMapboxRef={(e) => (mapboxRef.current = e)}
          mapBoxConfig={{
            center: contentInset as any,
            zoom: 16,
          }}
          showGeolocate={false}
          containerStyle={{
            width: window.innerWidth,
            height: window.innerHeight,
          }}
          isTrackPlayBack={isTrackPlayBack}
          coordinates={coordinates}
        >
          {!isTrackPlayBack && (
            <Marker center={contentInset}>
              <img src={ebikeDevice} alt="device" />
            </Marker>
          )}
          {!isTrackPlayBack && (
            <MapboxDrawComponent
              isShow={true}
              options={{
                keybindings: false,
                touchEnabled: false,
                displayControlsDefault: false,
                controls: {
                  polygon: true,
                  trash: true,
                },
                defaultMode: 'simple_select',
              }}
              FeatureCollection={{
                type: 'FeatureCollection',
                features: polygon,
              }}
              onSetData={(data) => {
                if (data) {
                  const drawData = {
                    polygon: data.features.map((item) => {
                      if (item.geometry.type === 'Polygon') {
                        return item.geometry.coordinates[0].map((item) => {
                          return { lat: item[0], lng: item[1] }
                        })
                      }
                      return item
                    }),
                    type: 'draw',
                  }
                  const newWindow = window as any
                  if (newWindow?.ReactNativeWebView) {
                    newWindow.ReactNativeWebView.postMessage(
                      JSON.stringify(drawData),
                    )
                  }
                }
              }}
            />
          )}
        </BaseBoxMap>
      ) : null}

      {id && (
        <TheftReport
          reportId={id}
          showGeolocate={false}
          containerStyle={{
            width: window.innerWidth,
            height: window.innerHeight,
          }}
        ></TheftReport>
      )}
    </div>
  )
}

export default App
