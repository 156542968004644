import 'mapbox-gl/dist/mapbox-gl.css'
import { memo, ReactNode, useRef, useState } from 'react'
import mapboxgl, { LngLat } from 'mapbox-gl'
import { useDidMount, useDidUpdate, useWillUnmount } from 'rooks'
import { useTranslation } from 'react-i18next'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import './index.css'
import { MapContext } from './context'
import RouteReplay from './RouteReplay'
import carImg from '../car.png'

interface IProps {
  mapBoxConfig: Omit<mapboxgl.MapboxOptions, 'container'>
  containerStyle?: React.CSSProperties
  children?: ReactNode
  onMapboxRef?: (e: mapboxgl.Map) => void
  map?: mapboxgl.Map
  showZoom?: boolean
  showGeolocate?: boolean
  showFullscreen?: boolean
  showGeolocateTrigger?: boolean
  isTrackPlayBack: boolean
  coordinates: Array<any>
  visible?: boolean
}
const defaultCenter: [number, number] = [0, 0]
const defaultZoom = 15
const defaultStyle = 'mapbox://styles/mapbox/streets-v9'
const BaseBoxMap = memo((props: IProps) => {
  const {
    showZoom = true,
    showGeolocate = true,
    showFullscreen = true,
    showGeolocateTrigger = false,
    isTrackPlayBack = false,
    coordinates = [],
  } = props
  const elRef = useRef<HTMLDivElement>()
  const [ready, setReady] = useState(false)
  const [isShowOperateBtn, setShowOperateBtn] = useState(true)
  const [initMap, setInitMap] = useState<mapboxgl.Map | undefined>(props.map)
  const GeolocateControlRef = useRef<mapboxgl.GeolocateControl>()
  const mapContainerRef = useRef<any>()
  const { t } = useTranslation()
  const routeReplayRef = useRef<any>()
  const routeGeoJson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      },
    ],
  }

  useDidMount(() => {
    const ops: mapboxgl.MapboxOptions = {
      container: elRef.current!,
      style: defaultStyle,
      accessToken:
        'pk.eyJ1IjoiYm9iY2F0bWluZXIiLCJhIjoiY2t1a3h2Y3dmMjFldDJ3dDltcTR3a2R1bCJ9.Im7PoMZ3X1UPENE71SJTfQ',
      zoom: defaultZoom,
      minZoom: 0,
      maxZoom: 22,
      center: LngLat.convert(defaultCenter),
      ...props.mapBoxConfig,
    }

    if (!initMap) {
      const map = new mapboxgl.Map(ops)
      if (showGeolocate) {
        const GeolocateControl = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          fitBoundsOptions: {
            zoom: map.getZoom(),
          },
          trackUserLocation: true,
          showUserHeading: true,
        })
        GeolocateControlRef.current = GeolocateControl
        map.addControl(GeolocateControl)
      }
      setInitMap(map)
    }
  })

  useDidUpdate(() => {
    if (initMap) {
      initMap.on('load', (evt: React.SyntheticEvent<any>) => {
        if (isTrackPlayBack) {
          routeReplayRef.current = new RouteReplay(
            initMap,
            routeGeoJson,
            carImg,
          )
        }
        // mapboxRef.current = initMap
        if (typeof props.onMapboxRef === 'function') {
          props.onMapboxRef(initMap)
        }
        if (showFullscreen) {
          initMap.addControl(
            new mapboxgl.FullscreenControl({
              container: document.querySelector('body'),
            }),
          )
        }
        if (showZoom) {
          initMap.addControl(
            new mapboxgl.NavigationControl({ showCompass: false }),
            'bottom-right',
          )
        }
        if (showGeolocateTrigger && GeolocateControlRef.current) {
          GeolocateControlRef.current.trigger()
        }
        setReady(true)
      })
    }
  }, [initMap, showGeolocateTrigger, isTrackPlayBack])

  useDidUpdate(() => {
    if (initMap && props.mapBoxConfig.center) {
      initMap.flyTo({
        center: props.mapBoxConfig.center,
        zoom: isTrackPlayBack ? 18 : initMap.getZoom(),
        bearing: 0,
        speed: 0.8,
        curve: 1,
        easing(t) {
          return t
        },
      })
    }
  }, [initMap, props.mapBoxConfig.center, isTrackPlayBack])

  useWillUnmount(() => {
    if (initMap) {
      initMap.remove()
      setReady(false)
    }
  })

  function startClick() {
    routeReplayRef.current.start()
  }

  function pauseClick() {
    routeReplayRef.current.pause()
  }

  function endClick() {
    routeReplayRef.current.end()
  }

  function removeClick() {
    routeReplayRef.current.remove()
    setShowOperateBtn(false)
  }

  const setContainer = (el: HTMLDivElement | null) => {
    if (el) elRef.current = el
  }

  return (
    <MapContext.Provider value={initMap}>
      <div ref={setContainer} style={{ ...props.containerStyle }}>
        {ready ? props.children : null}
      </div>
      {isTrackPlayBack && (
        <div
          id="map-container"
          ref={mapContainerRef}
          style={{ height: '100vh', width: '100vw' }}
        />
      )}
      {isTrackPlayBack && isShowOperateBtn && (
        <div style={{ position: 'fixed', top: '0', right: '0' }}>
          <button
            onClick={() => {
              startClick()
            }}
            style={{ marginRight: '10px' }}
          >
            {t('geofencing.start')}
          </button>
          <button
            onClick={() => {
              pauseClick()
            }}
            style={{ marginRight: '10px' }}
          >
            {t('geofencing.pause')}
          </button>
          <button
            onClick={() => {
              endClick()
            }}
            style={{ marginRight: '10px' }}
          >
            {t('geofencing.stop')}
          </button>
          <button
            onClick={() => {
              removeClick()
            }}
            style={{ marginRight: '10px' }}
          >
            {t('geofencing.remove')}
          </button>
        </div>
      )}
    </MapContext.Provider>
  )
})

const BaseBoxMapBox = memo((props: IProps) => {
  if (props.visible) {
    return <BaseBoxMap {...props} />
  }
  return null
})

export { BaseBoxMap, BaseBoxMapBox }
