import { useEffect, useRef, memo, useMemo } from 'react'
import mapboxgl from 'mapbox-gl'
import { useTranslation } from 'react-i18next'
import { withMap } from './context'
import { useSetState, useUnmount, useUpdateEffect } from 'ahooks'
import * as turf from '@turf/turf'
import { Button, Space, Toast } from 'antd-mobile'
import lodash from 'lodash'

interface CircleFenceProps {
  map: mapboxgl.Map | undefined
  center: any
  featureCollection: any[]
  onSetData?: (data: any[] | undefined) => void
  isShow?: boolean
}

const defaultColor = '#007cbf'
const selectedColor = '#ff0000'
const maxFeatures = 1

const CircleFence = ({
  map,
  featureCollection,
  onSetData,
}: CircleFenceProps) => {
  const { t } = useTranslation()
  const [state, setState] = useSetState<{
    circleAdd: 'ready' | 'preSelection' // 预选 = preSelection 准备好了 = ready
    featureCollection: any[]
    clickedCircleId: number
    circleStatus: string
    circleRadii: any
    refresh: number
    setDataVisible: boolean
    preSelection: any
  }>({
    circleAdd: 'ready',
    featureCollection: [],
    clickedCircleId: 0,
    circleStatus: '0', // 0 = 未选择、1 = 新增、2 = 增大、3 = 减小
    circleRadii: {},
    refresh: Date.now(),
    setDataVisible: false,
    preSelection: null,
  })
  const selectedIdRef = useRef()
  const initialDistanceRef = useRef<any>()
  const startTouchPositionRef = useRef<any>()
  // 创建围栏
  const createFence = (e: any) => {
    if (state.circleAdd === 'preSelection') {
      if (state.featureCollection.length >= maxFeatures) return false
      const center = [e.lngLat.lng, e.lngLat.lat]
      const radius = 50 // 初始半径50米
      const circle = turf.circle(center, radius, { steps: 64, units: 'meters' })
      const newFence = { id: Date.now(), data: circle, radius: radius }
      setState({
        featureCollection: [...state.featureCollection, newFence],
        preSelection: newFence,
      })
      updateMapWithFences([...state.featureCollection, newFence])
    } else {
      const features = map?.queryRenderedFeatures(e.point)
      if (
        features?.length &&
        features[0].layer.id === `circle-${selectedIdRef.current}`
      ) {
        state.featureCollection.forEach((item) => {
          if (selectedIdRef.current === item.id) {
            map?.setPaintProperty(
              `circle-${item.id}`,
              'fill-color',
              selectedColor,
            )
          } else {
            map?.setPaintProperty(
              `circle-${item.id}`,
              'fill-color',
              defaultColor,
            )
          }
        })
      } else {
        if (selectedIdRef.current) {
          map?.setPaintProperty(
            `circle-${selectedIdRef.current}`,
            'fill-color',
            defaultColor,
          )
          selectedIdRef.current = undefined
        }
      }
      setState({ refresh: Date.now() })
    }
  }

  const updateMapWithFences = (fencesToUpdate: any[]) => {
    if (!map) return
    fencesToUpdate.forEach((fence) => {
      const sourceId = `circle-${fence.id}`
      const source = map.getSource(sourceId)
      if (source) {
        if (source.type === 'geojson') {
          source.setData(fence.data)
        }
      } else {
        map.addSource(sourceId, {
          type: 'geojson',
          data: fence.data,
        })
        map.addLayer({
          id: sourceId,
          type: 'fill',
          source: sourceId,
          layout: {},
          paint: {
            'fill-color': '#007cbf',
            'fill-opacity': 0.8,
          },
        })
        map.on('click', sourceId, () => (selectedIdRef.current = fence.id))
      }
    })
    if (typeof onSetData === 'function') {
      if (state.circleAdd === 'ready' && state.featureCollection.length) {
        onSetData(fencesToUpdate)
      }
    }
  }

  const onTouchStart = (e: any) => {
    // 确定触摸开始点
    // console.log('onTouchStart =>', e)
    const features = map?.queryRenderedFeatures(e.point)
    if (
      features?.length &&
      features[0].layer.id === `circle-${selectedIdRef.current}`
    ) {
      if (lodash.get(e, 'originalEvent.touches.length', 0) === 2) {
        initialDistanceRef.current = getDistanceBetweenTouches(
          lodash.get(e, 'originalEvent.touches'),
        )
        map?.touchZoomRotate.disable()
      }
      map?.dragPan.disable()
      startTouchPositionRef.current = e.point
    } else {
      startTouchPositionRef.current = ''
    }
  }

  const lerp = (start: number, end: number, t: number) =>
    (1 - t) * start + t * end

  const onTouchMove = (e: any) => {
    const features = map?.queryRenderedFeatures(e.point)
    if (!features?.length) return
    const visible =
      lodash.get(e, 'originalEvent.touches.length', 0) === 2 &&
      initialDistanceRef.current &&
      features[0].layer.id === `circle-${selectedIdRef.current}`
    if (visible) {
      const currentDistance = getDistanceBetweenTouches(
        lodash.get(e, 'originalEvent.touches'),
      )
      const t = 0.05
      const adjustedScaleFactor = lerp(
        1,
        currentDistance / initialDistanceRef.current,
        t,
      )
      adjustFenceRadius(selectedIdRef.current, adjustedScaleFactor)
    } else {
      lodash.throttle(() => {
        if (startTouchPositionRef.current && selectedIdRef.current) {
          // 计算触摸移动的距离和方向
          const endTouchPosition = e.point
          const moveLngLat = map?.unproject([
            endTouchPosition.x,
            endTouchPosition.y,
          ])
          const startLngLat = map?.unproject([
            startTouchPositionRef.current.x,
            startTouchPositionRef.current.y,
          ])
          // 使用Turf平移围栏
          translateFence(selectedIdRef.current, startLngLat, moveLngLat)
          // 更新起始位置，以便平滑移动
          startTouchPositionRef.current = endTouchPosition
        } else {
          map?.dragPan.enable()
        }
      }, 200)()
    }
  }

  const getDistanceBetweenTouches = (touches: any[]) => {
    const [touch1, touch2] = touches
    const dx = touch1.clientX - touch2.clientX
    const dy = touch1.clientY - touch2.clientY
    return Math.sqrt(dx * dx + dy * dy)
  }

  const adjustFenceRadius = (selectedFence: any, scaleFactor: any) => {
    if (!selectedFence) return
    const selectFence = state.featureCollection.filter(
      (item) => item.id === selectedFence,
    )
    const vertices = selectFence[0].data.geometry.coordinates[0]
    const center = turf.center(turf.points(vertices))
    const newRadius = selectFence[0].radius * scaleFactor
    if (newRadius > 15 && newRadius < 1000) {
      const updatedFence = turf.circle(center, newRadius, {
        steps: 64,
        units: 'meters',
      })
      const updatedFences = state.featureCollection.map((fence) =>
        fence.id === selectedFence
          ? { ...fence, data: updatedFence, radius: newRadius }
          : fence,
      )
      setState({ featureCollection: updatedFences })
      updateMapWithFences(updatedFences)
    }
  }

  const translateFence = (fenceId: any, startLngLat: any, endLngLat: any) => {
    const selectedFence = state.featureCollection.find(
      (fence) => fence.id === fenceId,
    )
    if (!selectedFence) return

    // 计算平移的距离和方向
    const distance = turf.distance(
      turf.point([startLngLat.lng, startLngLat.lat]),
      turf.point([endLngLat.lng, endLngLat.lat]),
      { units: 'meters' },
    )
    const bearing = turf.bearing(
      turf.point([startLngLat.lng, startLngLat.lat]),
      turf.point([endLngLat.lng, endLngLat.lat]),
    )

    // 平移围栏
    const translatedFence = turf.transformTranslate(
      selectedFence.data,
      distance,
      bearing,
      { units: 'meters' },
    )

    // 更新围栏数据
    const newFences = state.featureCollection.map((fence) =>
      fence.id === fenceId ? { ...fence, data: translatedFence } : fence,
    )
    setState({ featureCollection: newFences })
    updateMapWithFences(newFences)
  }

  const onTouchEnd = () => {
    if (!startTouchPositionRef.current || !selectedIdRef.current) return
    map?.dragPan.enable()
    map?.touchZoomRotate.enable()
  }

  const currentRadius = (flag: boolean) => {
    if (!map) return
    if (selectedIdRef.current) {
      const newFeatureCollection = state.featureCollection.map((item) => {
        if (item.id === selectedIdRef.current) {
          let newRadius = item.radius
          if (flag) {
            newRadius += 5
          } else {
            newRadius -= 5
          }
          const newCircle = turf.circle(
            turf.centerOfMass(item.data).geometry.coordinates,
            newRadius,
            { steps: 64, units: 'meters' },
          )
          return { ...item, data: newCircle, radius: newRadius }
        }
        return item
      })
      setState({ featureCollection: newFeatureCollection })
      updateMapWithFences(newFeatureCollection)
    }
  }

  useEffect(() => {
    if (map) {
      map.on('click', createFence)
      map.on('touchstart', onTouchStart)
      map.on('touchmove', onTouchMove)
      map.on('touchend', onTouchEnd)
      map.on('touchcancel', onTouchEnd)
    }
    return () => {
      if (map) {
        map.off('click', createFence)
        map.off('touchstart', onTouchStart)
        map.off('touchmove', onTouchMove)
        map.off('touchend', onTouchEnd)
        map.off('touchcancel', onTouchEnd)
      }
    }
  }, [map, state])

  useUnmount(() => {
    if (map) {
      state.featureCollection.forEach((item) => {
        const sourceId = `circle-${item.id}`
        map.removeLayer(sourceId)
        map.removeSource(sourceId)
        map.off('click', sourceId, () => {})
      })
    }
  })

  useUpdateEffect(() => {
    if (featureCollection.length) {
      updateMapWithFences(featureCollection)
      setState({
        featureCollection: featureCollection,
      })
    }
  }, [featureCollection])

  const buttonObject = useMemo(() => {
    if (state.circleAdd === 'preSelection') {
      return {
        text: 'Success',
        color: 'default',
        backgroundColor: '#fa9d57',
      }
    }
    return {
      text: 'Add',
      color: 'default',
      backgroundColor: '#fff',
    }
  }, [state.circleAdd])

  return (
    <div
      style={{
        position: 'absolute',
        right: 10,
        top: 50,
      }}
    >
      <Space direction="vertical">
        <Button
          disabled={selectedIdRef.current}
          color={buttonObject.color as any}
          style={{
            width: 100,
            backgroundColor: buttonObject.backgroundColor,
          }}
          onClick={() => {
            if (!map) return
            if (
              state.featureCollection.length >= maxFeatures &&
              state.circleAdd === 'ready'
            ) {
              Toast.show({
                content: 'Exceeding the maximum limit for setting geofencing',
              })
            } else {
              switch (state.circleAdd) {
                case 'ready':
                  setState({ circleAdd: 'preSelection' })
                  break
                case 'preSelection':
                  if (state.preSelection) {
                    setState({ circleAdd: 'ready' })
                    if (state.preSelection) {
                      if (typeof onSetData === 'function') {
                        onSetData([state.preSelection])
                      }
                    }
                  } else {
                    setState({ circleAdd: 'ready' })
                  }
                  break
              }
              state.featureCollection.forEach((feature: any) => {
                const sourceId = `circle-${feature.id}`
                map.setFeatureState(
                  { source: sourceId, id: feature.id },
                  { selected: false },
                )
              })
            }
          }}
        >
          {buttonObject.text}
        </Button>
        <Button
          disabled={!selectedIdRef.current}
          style={{ width: 100 }}
          onClick={() => {
            if (selectedIdRef.current) {
              const filterCollection = state.featureCollection.filter(
                (item) => {
                  if (item.id !== selectedIdRef.current) {
                    return item
                  } else {
                    const id = `circle-${item.id}`
                    map?.removeLayer(id)
                    map?.removeSource(id)
                    return false
                  }
                },
              )
              selectedIdRef.current = undefined
              setState({ featureCollection: filterCollection })
              updateMapWithFences(filterCollection)
            }
          }}
        >
          {t('geofencing.Delete')}
        </Button>
      </Space>
    </div>
  )
}

export default memo(withMap<any>(CircleFence))
