import mapboxgl, { LngLat } from 'mapbox-gl'
import { ReactNode, memo, useCallback, useRef, useState } from 'react'
import { useDidMount, useDidUpdate } from 'rooks'
import { withMap } from './context'
interface IProps {
  center: any
  children?: JSX.Element
  setHTML?: string
  options?: mapboxgl.MarkerOptions | undefined
  onRef?: (e: mapboxgl.Marker) => void
  popupOptions?: mapboxgl.Popup
  popupComponent?: ReactNode
  draggable?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
  onScroll?: React.UIEventHandler<HTMLDivElement>
  onWheel?: React.MouseEventHandler<HTMLDivElement>
  onDragEnd?: (lngLat: LngLat) => void
}

interface MarkerLayoutIProps extends IProps {
  map: mapboxgl.Map | undefined
}
const defaultAnchor = 'bottom'
const MarkerProjectedLayer = memo((props: MarkerLayoutIProps) => {
  const {
    onClick,
    onDoubleClick,
    onMouseEnter,
    onMouseLeave,
    onScroll,
    onWheel,
    options,
    onRef,
    draggable = false,
    onDragEnd,
  } = props
  const elRef = useRef<HTMLDivElement | undefined>()
  const [initMarker, setInitMarker] = useState<mapboxgl.Marker | undefined>()
  useDidMount(() => {
    const marker = new mapboxgl.Marker({
      element: elRef.current,
      anchor: defaultAnchor,
      draggable: draggable,
      ...options,
    })
    setInitMarker(marker)
  })

  const markerDragEnd = useCallback(() => {
    if (initMarker && onDragEnd) {
      onDragEnd(initMarker.getLngLat())
    }
  }, [initMarker])

  useDidUpdate(() => {
    if (initMarker) {
      if (typeof onRef === 'function') {
        onRef(initMarker)
      }
      initMarker.setLngLat(props.center)
      if (props.map) {
        initMarker.addTo(props.map)
      }
      initMarker.on('dragend', markerDragEnd)
    }
  }, [initMarker])

  useDidUpdate(() => setHTML(), [props.setHTML, initMarker])
  useDidUpdate(() => {
    if (initMarker) {
      initMarker.setLngLat(props.center)
    }
  }, [props.center, initMarker])
  useDidUpdate(() => setDraggable(), [props.options?.draggable, initMarker])
  const setHTML = useCallback(() => {
    if (initMarker && props.setHTML) {
      const warpDiv = `<div style="padding: 5px">${props.setHTML}</div>`
      const height = initMarker.getElement().clientHeight + 5
      initMarker?.setPopup(
        new mapboxgl.Popup({
          anchor: 'bottom',
          offset: [0, -height],
          ...props.popupOptions,
        }).setHTML(warpDiv),
      )
    }
  }, [props.setHTML, initMarker, props.popupOptions])
  const setDraggable = useCallback(() => {
    if (
      initMarker &&
      props.options &&
      Reflect.has(props.options, 'draggable')
    ) {
      if (initMarker?.isDraggable() !== props.options.draggable) {
        initMarker?.setDraggable(props.options.draggable as boolean)
      }
    }
  }, [initMarker, props.options])

  const setContainer = (el: HTMLDivElement | null) => {
    if (el) elRef.current = el
  }

  return (
    <div
      ref={setContainer}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onScroll={onScroll}
      onWheel={onWheel}
    >
      {props.children}
    </div>
  )
})

export default withMap<IProps>(MarkerProjectedLayer)
