import { useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { useDidMount, useDidUpdate } from 'rooks'
import { useTranslation } from 'react-i18next'
import { withMap } from './context'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import type { FeatureCollection } from 'geojson'
import { Button, Space, Toast } from 'antd-mobile'
import { useSetState } from 'ahooks'
interface IProps {
  options?: MapboxDraw.MapboxDrawOptions
  onRef?: (e: MapboxDraw) => void
  onSetData?: (data: FeatureCollection | undefined) => void
  FeatureCollection?: FeatureCollection
  isShow: boolean
}

interface DrawLayoutIProps extends IProps {
  map: mapboxgl.Map | undefined
}

const maxFeatures = 1

const DrawProjectedLayer = (props: DrawLayoutIProps) => {
  const { t } = useTranslation()
  const [initDraw, setInitDraw] = useState<MapboxDraw | undefined>()
  const [state, setState] = useSetState({
    isDrawing: false,
    direct_select: false,
    maxFeatures: 0,
  })

  useDidMount(() => {
    const drawOps: MapboxDraw.MapboxDrawOptions = {
      displayControlsDefault: false,
      controls: {
        polygon: false,
        trash: false,
      },
    }
    const draw = new MapboxDraw(drawOps)

    if (typeof props.onRef === 'function') {
      props.onRef(draw)
    }
    setInitDraw(draw)
  })

  useEffect(() => {
    if (initDraw && props.map) {
      props.map.addControl(initDraw)
      props.map.on('draw.update', handleDrawCreate)
      props.map.on('draw.modechange', handleModechange)
      props.map.on('draw.combine', handleCombine)
      props.map.on('draw.uncombine', handleUncombine)
      props.map.on('draw.actionable', handleActionable)
      props.map.on('draw.selectionchange', handleSelectionchange)
    }
    return () => {
      if (initDraw && props.map) {
        props.map.removeControl(initDraw)
        props.map.off('draw.update', handleDrawCreate)
        props.map.off('draw.modechange', handleModechange)
        props.map.off('draw.combine', handleCombine)
        props.map.off('draw.uncombine', handleUncombine)
        props.map.off('draw.actionable', handleActionable)
        props.map.off('draw.selectionchange', handleSelectionchange)
      }
    }
  }, [initDraw, props.map])

  useDidUpdate(() => {
    if (initDraw) {
      if (props.FeatureCollection?.features.length) {
        initDraw.set(props.FeatureCollection)
      }
    }
  }, [initDraw, props.FeatureCollection?.features])

  useDidUpdate(() => {
    if (initDraw && props.map) {
      if (props.isShow) {
        const isadded = props.map.hasControl(initDraw)
        if (isadded) return
        props.map.addControl(initDraw)
        if (props.FeatureCollection?.features.length) {
          initDraw.set(props.FeatureCollection)
        }
      } else {
        props.map.removeControl(initDraw)
      }
    }
  }, [props.isShow])

  const handleModechange = (e: any) => {
    console.log('handleModechange =>', e)

    // 开始
    if (e.mode === 'draw_polygon') {
      // 开始绘制多边形，可以在这里改变按钮颜色或状态
    }

    if (e.mode === 'direct_select') {
      // 进入编辑模式
      // const data = initDraw?.getAll()
      // if (data?.features.length) {}
      // console.log('handleModechange direct_select updata=>', data)
    }

    // 停止
    if (e.mode === 'simple_select') {
      const data = initDraw?.getAll()
      setState({ direct_select: false, isDrawing: false })
    }
  }

  const handleSelectionchange = (e: any) => {
    const hasSelection = e.features.length > 0
    setState({ direct_select: hasSelection })
  }

  const drawCreate = () => {
    if (initDraw) {
      if (state.isDrawing) {
        setState({ isDrawing: false })
        initDraw.changeMode('simple_select')
        const allFeatures = initDraw.getAll()
        if (typeof props.onSetData === 'function') {
          props.onSetData(allFeatures)
        }
      } else {
        if (initDraw.getAll().features.length >= maxFeatures) {
          Toast.show({
            content: 'Exceeding the maximum limit for setting geofencing',
            afterClose: () => {},
          })
        } else {
          setState({ isDrawing: true })
          initDraw.changeMode('draw_polygon')
        }
      }
    }
  }

  const drawDelete = () => {
    if (initDraw) {
      const selectedIds = initDraw.getSelectedIds()
      initDraw.delete(selectedIds)
      const allFeatures = initDraw.getAll()
      if (typeof props.onSetData === 'function') {
        props.onSetData(allFeatures)
      }
      setState({ isDrawing: false, direct_select: false })
    }
  }

  const handleCombine = (e: any) => {
    // 当两个或多个几何对象合并为一个对象时触发
    console.log('handle Combine =>', e)
  }
  const handleUncombine = (e: any) => {
    // 当一个合并的几何对象被拆分成原始的单独对象时触发
    console.log('handle Uncombine =>', e)
  }
  const handleActionable = (e: any) => {
    // 当绘制的状态改变为可执行特定操作时触发
    console.log('handle Actionable =>', e)
    // if (e.actions.trash) {
    //   setState({ direct_select: true })
    // }
  }

  const handleDrawCreate = (e: any) => {
    const data = initDraw?.getAll()
    if ((data?.features.length || [].length) > maxFeatures) {
      Toast.show({
        content: 'Exceeding the maximum limit for setting geofencing',
        afterClose: () => {
          if (initDraw) {
            const latestFeature = data?.features[data.features.length - 1]
            if (latestFeature && latestFeature.id) {
              initDraw.delete(latestFeature.id as string)
            }
          }
        },
      })
    } else {
      if (typeof props.onSetData === 'function') {
        props.onSetData(data)
      }
    }
  }

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          right: 10,
          top: 50,
        }}
      >
        <Space direction="vertical">
          <Button
            disabled={state.direct_select}
            style={{
              backgroundColor: state.isDrawing ? '#fa9d57' : '#fff',
              width: 100,
            }}
            onClick={drawCreate}
          >
            {state.isDrawing ? 'Success' : 'Add'}
          </Button>
          <Button
            style={{ width: 100 }}
            disabled={!state.direct_select}
            onClick={drawDelete}
          >
            {t('geofencing.Delete')}
          </Button>
        </Space>
      </div>
    </div>
  )
}

export default withMap<IProps>(DrawProjectedLayer)
