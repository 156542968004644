import { ExternalApiUrl } from '../api/api.url'
import { GlobalAxios } from '../api/http'

export function onGetTheftReportById(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ExternalApiUrl.externalReportById.replace('{{id}}', id),
    },
    alertMsg: 'Get theft reports by id.',
  }).fetch<any>('GET')
}

export function onGetDeviceByTheftReportId(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ExternalApiUrl.externalDeviceByReportId.replace('{{id}}', id),
    },
    alertMsg: 'Get device by theft report id.',
  }).fetch<any>('GET')
}
