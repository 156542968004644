import MapboxDrawComponent from '../../../mapbox/draw'
import { useMount, useSetState } from 'ahooks'

const DrawScreen = () => {
  const [state, setState] = useSetState({
    contentInset: [0, 0],
    polygon: [],
    visible: false,
  })

  useMount(() => {
    const Geofencing = sessionStorage.getItem('Geofencing')
    if (Geofencing) {
      const GeofencingParse = JSON.parse(Geofencing)
      setState({
        contentInset: GeofencingParse.contentInset,
        polygon:
          GeofencingParse.polygonType === 'draw'
            ? GeofencingParse.polygon.map((item: any) => {
                return {
                  geometry: {
                    coordinates: [
                      item.map((coordinate: any) => {
                        return [coordinate.lng, coordinate.lat]
                      }),
                    ],
                    type: 'Polygon',
                  },
                  properties: {},
                  type: 'Feature',
                }
              })
            : [],
        visible: true,
      })
    } else {
      setState({
        visible: true,
      })
    }
  })

  return (
    <MapboxDrawComponent
      isShow={true}
      options={{
        keybindings: false,
        touchEnabled: false,
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        defaultMode: 'simple_select',
      }}
      FeatureCollection={{
        type: 'FeatureCollection',
        features: state.polygon,
      }}
      onSetData={(data) => {
        if (data) {
          const drawData = {
            polygon: data.features.map((item) => {
              if (item.geometry.type === 'Polygon') {
                return item.geometry.coordinates[0].map((item) => {
                  return { lng: item[0], lat: item[1] }
                })
              }
              return item
            }),
            type: 'draw',
          }
          const newWindow = window as any
          if (newWindow?.ReactNativeWebView) {
            newWindow.ReactNativeWebView.postMessage(JSON.stringify(drawData))
          }
        }
      }}
    />
  )
}

export default DrawScreen
